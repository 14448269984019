/*
  ----------------
  Service
  ----------------
*/

body.service {

  .heading {
    &__wrap {
      align-items: center;
      display: flex;
      padding-top: 80px;
      position: relative;
      @media #{$sp} {
        padding-top: 21vw;
      }
    }
    &__bg {
      width: 100%;
      z-index: -1;
    }
    &__image {
      vertical-align: bottom;
      width: 100%;
    }
    &__box {
      align-items: center;
      display: flex;
      justify-content: center;
      padding-top: 68px;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      @media #{$sp} {
        text-align: center;
        top: 0;
        left: 0;
        padding-top: 17vw;
      }
    }
    &__title {
      font-family: $open-sans;
      color: #fff;
      font-size: 60px;
      font-weight: bold;
      letter-spacing: -2px;
      @media #{$sp} {
        font-size: 56px;
        letter-spacing: 0;
      }
    }
    &__title-sub {
      font-size: 1vw;
      text-align: center;
      @media #{$sp} {
        font-size: 4vw;
        margin-top: 5px;
      }
    }
  }

  .title {
    font-size: 40px;
    font-weight: 300;
    text-align: center;
    @media #{$sp} {
      font-size: 32px;
    }
  }

  .service-contents {
    &__wrap {
      padding-top: 50px;
    }
    &__box {
      display: flex;
      @media #{$pc} {
        height: 38vw;
        max-height: 730px;
        min-height: 420px;
      }
      @media #{$sp} {
        display: block;
      }
    }
    &__image {
      background: url(../img/service/contents_first.jpg) 0 50% / cover no-repeat;
      flex-basis: 50%;
      @media #{$sp} {
        height: 75.8vw;
      }
      &--reverse {
        background: url(../img/service/contents_second.jpg) 50% / cover no-repeat;
        @media #{$pc} {
          order: 2;
        }
      }
    }
    &__image-inner {
      vertical-align: bottom;
    }
    &__inner {
      flex-basis: 50%;
      padding: 2vw 0 2vw 2vw;
      @media #{$sp} {
        flex-basis: 100%;
        padding: 20px;
      }
      &--reverse {
        @media #{$pc} {
          order: 1;
          padding: 2vw 2vw 2vw 0;
        }
      }
    }
    &__inner-bg {
      align-items: center;
      background: #f4f7f4;
      display: flex;
      height: 100%;
      justify-content: center;
    }
    &__inner-wrap {
      padding: 0 15px 15px 15px;
      text-align: center;
      @media #{$sp} {
        margin: 0;
        padding: 20px;
      }
    }
    &__title {
      letter-spacing: -5px;
      margin-top: .8vw;
      @media all and (min-width: 1600px) {
        font-size: 40px;
      }
      @media all and (min-width: 768px) and (max-width: 1599px) {
        font-size: 30px;
        letter-spacing: -3px;
      }
      @media #{$sp} {
        font-size: 32px;
      }
      @media all and (min-width: 320px) and (max-width: 374px) {
        font-size: 31px;
      }
    }
    &__list {
      display: inline-block;
      margin-top: 1.3vw;
      padding-left: 30px;
      @media #{$sp} {
        display: block;
        margin-top: 5px;
        padding-left: 15px;
      }
    }
    &__item {
      font-weight: 600;
      text-align: left;
      line-height: 2.2;
      list-style: disc;
      @media all and (min-width: 768px) and (max-width: 1000px) {
        line-height: 1.3;
      }
      @media #{$sp} {
        letter-spacing: -0.5px;
        line-height: 1.5;
      }
    }
    &__text {
      font-size: calc(13px + ((1vw - 12px) * 0.7));
      letter-spacing: -1px;
      line-height: 1.5;
      margin-top: 1vw;
      @media all and (min-width: 1500px) {
        font-size: 16px;
      }
      @media all and (min-width: 768px) and (max-width: 1200px) {
        font-size: 13px;
      }
      @media #{$sp} {
        font-size: 16px;
        margin-top: 10px;
        padding: 0;
      }
    }
  }

  .service-consult {
    background: $gray;
    &__wrap {
      padding: 95px 20px 100px;
      @media #{$pc} {
        max-width: 1020px;
        margin: 0 auto;
      }
      @media #{$sp} {
        padding: 100px 20px;
      }
    }
    &__title {
      letter-spacing: -3px;
    }
    &__lead {
      line-height: 1.7;
      margin-top: 25px;
      @media #{$pc} {
        text-align: center;
      }
      @media #{$sp} {
        letter-spacing: -1.6px;
      }
    }
    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 35px;
      @media #{$sp} {
        margin-top: 50px;
      }
    }
    &__item {
      flex-basis: 48.6%;
      @media #{$pc} {
        &:nth-child(2n) {
          margin-left: 20px;
        }
        &:nth-child(n+3) {
          margin-top: 35px;
        }
      }
      @media #{$sp} {
        flex-basis: 100%;
        &+.service-consult__item {
          margin-top: 60px;
        }
      }
    }
    &__box {}
    &__image {}
    &__image-inner {
      width: 100%;
    }
    &__title-sub {
      font-size: 20px;
      font-weight: 600;
    }
    &__text {
      letter-spacing: -1.3px;
      line-height: 1.6;
      margin-top: 3px;
    }
  }

  .service-example {
    &__wrap {
      padding: 90px 20px 100px;
      @media #{$pc} {
        max-width: 1020px;
        margin: 0 auto;
      }
      @media #{$sp} {
        padding: 100px 20px;
      }
    }
    &__title {
    }
    &__contents {
      margin-top: 35px;
      @media #{$pc} {
        display: flex;
      }
      @media #{$sp} {
        margin-top: 5px;
      }
    }
    &__box {
      flex-basis: 50%;
      @media #{$pc} {
        &:last-child {
          margin-left: 20px;
        }
      }
      @media #{$sp} {
        &:last-child {
          margin-top: 35px;
        }
      }
    }
    &__image {}
    &__image-inner {
      width: 100%;
    }
    &__title-sub {
      font-family: $open-sans;
      font-size: 20px;
      font-weight: bold;
        text-align: center;
      @media #{$sp} {
        font-size: 17px;
        text-align: left;
        margin-top: -15px;
      }
    }
    &__link {
      margin-top: 80px;
      position: relative;
      text-align: center;

      @media #{$sp} {
        margin-top: 85px;
      }

      &::before {
        position: absolute;
        display: block;
        content: "";
        background: url(../img/common/arrow_green.png) 50% / cover no-repeat;
        bottom: -6px;
        left: 50%;
        width: 402px;
        height: 16px;
        transform: translateX(-50%);

        @media #{$sp} {
          background-size: contain;
          height: 4vmin;
          width: 100%;
        }
      }
    }

    &__link-inner {
      @include hoverlink;
      color: $green;
      font-size: 30px;
      letter-spacing: -1px;
      @media #{$sp} {
        width: 100%;
      }
    }
  }
}
