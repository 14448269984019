/*
  ----------------
  Header
  ----------------
*/
body.is-open {
  overflow: hidden;
}
.header {
  background: #fff;
  position: fixed;
  width: 100%;
  z-index: 10;
  .top & {
    @media #{$pc} {
      background: transparent;
    }
  }
  @media #{$sp} {
    background: #fff;
  }
  &__wrap {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    position: relative;
    .top & {
      @media #{$pc} {
        padding: 20px;
      }
    }
    @media #{$sp} {
      padding: 3.8vw;
    }
  }
  &__checkbox {
    display: none;
  }
  &__icon {
    cursor: pointer;
    display: block;
    width: 8.5vmin;
    height: 8.5vmin;
    position: fixed;
    right: 8%;
    z-index: 4;
  }
  &__icon-parts,
  &__icon-parts:before,
  &__icon-parts:after {
    background-color: $green;
    display: block;
    width: 100%;
    height: 2px;
    position: absolute;
    top: 0;
    right: 0%;
    left: 0;
    bottom: 0;
    margin: auto;
  }

  &__icon-parts:before,
  &__icon-parts:after {
    content: " ";
  }

  &__icon-parts:before {
    top: 6vmin;
  }

  &__icon-parts:after {
    top: -6vmin;
  }
  &__overlay {
    background: #000;
    opacity: 0;
    pointer-events: none; //ポインタの動作全部無効化
    width: 100%;
    height: 100%;
    position: fixed;
    top: 20vw;
    right: 0;
    z-index: 2;
  }
  &__logo {

  }
  &__logo-link {
    @include hoverlink;
  }
  &__image {
    @media #{$sp} {
      height: 11vmin;
    }
  }
  &__menu {
    @media #{$sp} {
      overflow-y: auto;
      opacity: 0;
      position: fixed;
      top: 19.8vw;
      right: -100vw;
      width: 100%;
      z-index: 3;
    }
  }
  &__list {
    align-items: center;
    display: flex;
    @media #{$sp} {
      background: #fff;
      flex-direction: column;
    }
  }
  &__item {
    @media #{$sp} {
      border-top: 1px solid #2d2d2d;
      width: 100%;
    }
  }
  &__link {
    @include hoverlink;
    font-family: $open-sans;
    font-size: 20px;
    font-weight: bold;
    color: $green;
    padding: 0 10px;
    @media #{$sp} {
      font-size: 7.3vw;
      padding: 3px 10px;
      text-align: center;
      width: 100%;
    }

    &--white {
      background: #fff;
      border: 1px solid $green;
      font-family: $font-family;
      font-size: 16px;
      margin-left: 27px;
      padding: 3px 29px;
    }
  }
  // チェックが入ったら
  &__checkbox:checked~ {

    .header__icon {
      .header__icon-parts {
        background: transparent;
      }

      .header__icon-parts:before {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: 0;
      }

      .header__icon-parts:after {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 0;
      }
    }
    .header__overlay {
      opacity: 0.3;
      pointer-events: auto;
    }
    .header__menu {
      opacity: 1;
      right: 0;
    }
  }
  &__icon-parts,
  &__icon-parts:after,
  &__icon-parts:before,
  &__overlay,
  &__menu {
    -webkit-transition: all .7s;
    transition: all .7s;
  }
}
