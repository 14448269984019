/*
  ----------------
  Footer
  ----------------
*/
.footer {
  background: $gray;
  &__wrap {
    @include container;
    padding: 40px 2% 50px;
    @media #{$sp} {
      padding: 30px 2% 50px;
    }
  }
  &__nav {
    display: flex;
    @media #{$sp} {
      flex-direction: column;
    }
  }
  &__brand {
    @media #{$sp} {
      text-align: center;
    }
  }
  &__logo {

  }
  &__image {
    @include hoverlink;
    width: 200px;
  }
  &__menu {
    align-items: flex-end;
    display: flex;
    @media #{$pc} {
      margin-left: 40px;
      padding-bottom: 12px;
    }
    @media #{$sp} {
      justify-content: center;
      margin-top: 8px;
    }
  }

  &__item {
    font-family: $open-sans;
    line-height: 1em;
    padding: 0 20px;
    position: relative;

    &:not(:first-child)::before {
      position: absolute;
      display: block;
      content: "|";
      top: 0;
      left: 0px;
      font-size: 13px;
    }
  }
  &__link {
    @include hoverlink;
   font-size: 15px;
   letter-spacing: 0;
  }
  &__box {
    display: flex;
    justify-content: flex-end;
    margin-top: 37px;
    @media #{$sp} {
      display: block;
      text-align: center;
      flex-direction: column;
    }
  }
  &__policy {
    @include hoverlink;
    color: #666;
    border-bottom: 1px solid #666;
    height: 1.6em;
    @media #{$sp} {
      display: content;
    }
  }
  &__security-policy {
    @include hoverlink;
    margin-left: 20px;
    color: #666;
    border-bottom: 1px solid #666;
    height: 1.6em;
    @media #{$sp} {
      display: content;
    }
  }
  &__copy {
    color: #666;
    font-family: $open-sans;
    font-size: 14px;
    letter-spacing: 0.4px;
    @media #{$pc} {
      margin-left: 40px;
    }
  }
}