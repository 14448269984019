/*
  ----------------
  Work page
  ----------------
*/

body.work {

  .heading {
    &__wrap {
      align-items: center;
      display: flex;
      padding-top: 80px;
      position: relative;

      @media #{$sp} {
        padding-top: 21vw;
      }
    }

    &__bg {
      width: 100%;
      z-index: -1;
    }

    &__image {
      vertical-align: bottom;
      width: 100%;
    }

    &__box {
      align-items: center;
      display: flex;
      justify-content: center;
      padding-top: 68px;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;

      @media #{$sp} {
        text-align: center;
        top: 0;
        left: 0;
        padding-top: 17vw;
      }
    }

    &__title {
      font-family: $open-sans;
      color: #fff;
      font-size: 60px;
      font-weight: bold;
      letter-spacing: -2px;

      @media #{$sp} {
        font-size: 56px;
        letter-spacing: 0;
      }
    }

    &__title-sub {
      font-size: 1vw;
      text-align: center;

      @media #{$sp} {
        font-size: 4vw;
        margin-top: 5px;
      }
    }
  }

  .work-main {
    background: $gray;
    position: relative;
    padding: 120px 2%;

    @media #{$sp} {
      padding: 120px 5%;
    }

    &__wrap {
      @include container;
    }

    &__contents {
      margin-top: 5px;
    }

    &__item {
      border-top: 1px solid #4d4d4d;
      padding: 13px 0;

      &:last-child {
        border-bottom: 1px solid #4d4d4d;
      }
    }

    &__item-wrap {
      display: flex;
      align-items: baseline;
    }

    &__heading {
      flex-basis: 14.5%;
      padding-left: 40px;

      @media #{$sp} {
        flex-basis: 32%;
        padding-left: 0;
      }
    }

    &__detail {
      flex-basis: 68%;
      line-height: 1.6;

      @media #{$sp} {}
    }

    &__address {
      margin-top: 35px;

      @media #{$sp} {
        margin-top: 30px;
      }
    }

    &__address-title {
      font-size: 20px;
      text-align: center;
    }

    &__address-text {
      text-align: center;
      line-height: 1.6;
      margin-top: 5px;

      @media #{$sp} {
        letter-spacing: 0;
      }
    }

    &__map {
      margin-top: 13px;

      @media #{$sp} {
        margin: 15px calc(((100vw - 100%) / 2) * -1) 0;
      }
    }

    &__map-inner {
      width: 100%;
      height: 283px;
    }
  }

  .work-contact {
    overflow: hidden;

    &__wrap {
      max-width: 640px;
      margin: 0 auto;
      padding: 125px 0 200px;
      position: relative;

      @media #{$sp} {
        padding: 80px 5% 130px;
      }

      &::before {
        position: absolute;
        display: block;
        content: "";
        background: url(../img/top/about_leaf.png) 50% / cover no-repeat;
        bottom: -12px;
        left: 51%;
        width: 144px;
        height: 190px;
        transform: translateX(-50%);

        @media all and (min-width: 769px) and (max-width: 920px) {
          right: -225px;
        }

        @media #{$sp} {
          left: 56%;
          height: 135px;
          width: 100px;
        }
      }
    }

    &__title {
      font-size: 40px;
      text-align: center;

      @media #{$sp} {
        font-size: 32px;
        font-weight: 300;
      }
    }

    &__widget {
      margin-top: 35px;
      position: relative;
      z-index: 2;
    }

    &__form {}

    &__item {
      &+.contact-form__item {
        margin-top: 11px;
      }
    }

    &__check {
      background: #f4f7f4;
      border-radius: 4px;
      padding: 10px;
    }

    &__check-box {
      margin-left: 5px;
    }

    &__title-sub {
      display: block;
      font-weight: 600;
    }

    &__required {
      color: #ff0000;
      font-weight: normal;
    }

    &__input {
      appearance: none;
      background: #f4f7f4;
      border: none;
      border-radius: 4px;
      display: block;
      font-size: 14px;
      outline: none;
      padding: 13px 10px;
      width: 100%;

      @media #{$sp} {
        font-size: 14px;
      }

      &::-ms-expand {
        display: none;
      }

      &--select {
        cursor: pointer;
      }

      &--text {
        height: 110px;
        resize: none;
        overflow: auto;
      }
    }

    &__input-arrow {
      position: relative;

      &::before {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 6px solid #666666;
        content: '';
        pointer-events: none;
        position: absolute;
        right: 16px;
        transform: translateY(-50%);
        top: 50%;
        width: 0;
      }
    }

    &__policy {
      margin-top: 28px;
    }

    &__policy-title {
      font-size: 16px;
    }

    &__policy-text {
      background: #fff;
      border: 1px solid #ddd;
      font-size: 14px;
      height: 12em;
      letter-spacing: -1px;
      line-height: 1.8;
      overflow: auto;
      margin-top: 8px;
      padding: 12px 20px;
    }

    &__submit {
      text-align: center;
      margin-top: 40px;
    }

    &__button {
      @include hoverlink;
      background: #3f9f28;
      color: #fff;
      font-size: 20px;
      letter-spacing: -1px;
      padding: 15px 65px;

      @media #{$sp} {
        padding: 15px;
        width: 100%;
      }

      @media all and (min-width: 320px) and (max-width: 374px) {
        padding: 15px 10px;
        font-size: 18px;
      }
    }
  }
}