/*
  ----------------
  文章のスタイル設定
  ----------------
*/

body {
  font-family: $font-family;
  font-weight: 300;
}
