/*
  ----------------
  Contact check
  ----------------
*/
body.policy {
  .policy {
    overflow: hidden;
    &__wrap {
      max-width: 1020px;
      margin: 0 auto;
      padding: 125px 20px 100px;
      position: relative;
      @media #{$sp} {
        padding: 34vw 20px 70px;
      }
    }
    &__title {
      font-size: 40px;
      text-align: center;
      @media #{$sp} {
        font-size: 32px;
        font-weight: 300;
      }
    }
    &__contents {
      margin-top: 40px;
    }
    &__title-sub {
      display: block;
      font-size: 20px;
      font-weight: 700;
      &+.policy__text {
        margin-top: 10px;
      }
    }
    &__text {
      display: block;
      letter-spacing: -1.5px;
      line-height: 1.7;
      width: 100%;
      &+.policy__title-sub {
        margin-top: 60px;
      }
    }
  }
}
