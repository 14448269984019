/*
  ----------------
  Contact done
  ----------------
*/
body.done {
  .contact-done {
    overflow: hidden;
    &__wrap {
      max-width: 640px;
      margin: 0 auto;
      padding: 125px 0 480px;
      position: relative;
      @media #{$sp} {
        padding: 34vw 20px 270px;
      }
      &::before {
        background: url(../img/contact/leaf.png) 50% / cover no-repeat;
        bottom: -45px;
        content: "";
        display: block;
        height: 501px;
        position: absolute;
        right: -25vw;
        width: 230px;
        z-index: 1;
        @media all and (min-width: 769px) and (max-width: 920px) {
          right: -225px;
        }
        @media #{$sp} {
          bottom: -18px;
          height: 261px;
          left: 52%;
          transform: translateX(-50%);
          width: 120px;
        }
      }
    }
    &__title {
      font-size: 40px;
      text-align: center;
      @media #{$sp} {
        font-size: 32px;
        font-weight: 300;
      }
    }
    &__widget {
      margin-top: 50px;
      position: relative;
      z-index: 2;
      @media #{$sp} {
        margin-top: 35px;
      }
    }
    &__text {
      display: block;
      font-size: 14px;
      width: 100%;
      @media #{$sp} {
        font-size: 14px;
      }
    }
    &__submit {
      text-align: center;
      margin-top: 35px;
    }
    &__button {
      @include hoverlink;
      background: #3f9f28;
      color: #fff;
      font-size: 20px;
      letter-spacing: -1px;
      padding: 10px 150px;
      @media #{$sp} {
        padding: 10px;
        width: 100%;
      }
      @media all and (min-width: 320px) and (max-width: 374px) {
        padding: 15px 10px;
        font-size: 18px;
      }
    }
  }
}
