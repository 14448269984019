/*
  ----------------
  ユーティリティー
  ----------------
*/

// レスポンシブ用
.sp-only {
  @media #{$pc} {
    display: none !important;
  }
}

.pc-only {
  @media #{$sp} {
    display: none !important;
  }
}
