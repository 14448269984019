// Breakpoint設定
$sp: "all and (max-width : 768px)";

$pc: "all and (min-width: 769px)";

// Font-family
$font-family: 'ヒラギノ角ゴシック',
'Noto Sans Japanese',
'ヒラギノ角ゴ Pro W3',
'Hiragino Kaku Gothic Pro',
'游ゴシック',
'Yu Gothic',
'游ゴシック体',
'YuGothic',
'ＭＳ Ｐゴシック',
'MS PGothic',
sans-serif;
$open-sans: 'Open Sans', sans-serif;

// カラー
$gray: #f2f2f2;
$green: #3f9f28;

// コンテナー設定
$container: 980px;
