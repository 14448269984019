/*
  ----------------
  Contact
  ----------------
*/
body.contact {
  .contact-form {
    overflow: hidden;
    &__wrap {
      max-width: 640px;
      margin: 0 auto;
      padding: 125px 0 100px;
      position: relative;
      @media #{$sp} {
        padding: 36vw 20px 270px;
      }
      &::before {
        background: url(../img/contact/leaf.png) 50% / cover no-repeat;
        bottom: -45px;
        content: "";
        display: block;
        height: 501px;
        position: absolute;
        right: -25vw;
        width: 230px;
        z-index: 1;
        @media all and (min-width: 769px) and (max-width: 920px) {
          right: -225px;
        }
        @media #{$sp} {
          bottom: -18px;
          height: 261px;
          left: 52%;
          transform: translateX(-50%);
          width: 120px;
        }
      }
    }
    &__title {
      font-size: 40px;
      text-align: center;
      @media #{$sp} {
        font-size: 32px;
        font-weight: 300;
      }
    }
    &__widget {
      margin-top: 35px;
      position: relative;
      z-index: 2;
    }
    &__form {

    }
    &__item {
      &+.contact-form__item {
        margin-top: 11px;
      }
    }
    &__title-sub {
      display: block;
      font-weight: 600;
    }
    &__required {
      color: #ff0000;
      font-weight: normal;
    }
    &__input {
      appearance: none;
      background: #f4f7f4;
      border: none;
      border-radius: 4px;
      display: block;
      font-size: 14px;
      outline: none;
      padding: 13px 10px;
      width: 100%;
      @media #{$sp} {
        font-size: 14px;
      }
      &::-ms-expand {
        display: none;
      }
      &--select {
        cursor: pointer;
      }
      &--text {
        height: 110px;
        resize: none;
        overflow: auto;
      }
    }
    &__input-arrow {
      position: relative;

      &::before {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 6px solid #666666;
        content: '';
        pointer-events: none;
        position: absolute;
        right: 16px;
        transform: translateY(-50%);
        top: 50%;
        width: 0;
      }
    }
    &__policy {
      margin-top: 28px;
    }
    &__policy-title {
      font-size: 16px;
    }
    &__policy-text {
      background: #fff;
      border: 1px solid #ddd;
      font-size: 14px;
      height: 12em;
      letter-spacing: -1px;
      line-height: 1.8;
      overflow: auto;
      margin-top: 8px;
      padding: 12px 20px;
    }
    &__submit {
      text-align: center;
      margin-top: 40px;
    }
    &__button {
      @include hoverlink;
      background: #3f9f28;
      color: #fff;
      font-size: 20px;
      letter-spacing: -1px;
      padding: 15px 65px;
      @media #{$sp} {
        padding: 15px;
        width: 100%;
      }
      @media all and (min-width: 320px) and (max-width: 374px) {
        padding: 15px 10px;
        font-size: 18px;
      }
    }
  }
}
