// Container
@mixin container {
  max-width: #{$container};
  margin: 0 auto;
  position: relative;
}

@mixin hoverlink {
  transition: 0.2s ease-in-out;
  backface-visibility: hidden;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  &::before,
  &::after {
    backface-visibility: hidden;
  }
}
