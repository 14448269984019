/*
  ----------------
  Contact check
  ----------------
*/
body.check {
  .contact-check {
    overflow: hidden;
    &__wrap {
      max-width: 640px;
      margin: 0 auto;
      padding: 125px 0 150px;
      position: relative;
      @media #{$sp} {
        padding: 34vw 20px 275px;
      }
      &::before {
        background: url(../img/contact/leaf.png) 50% / cover no-repeat;
        bottom: -45px;
        content: "";
        display: block;
        height: 501px;
        position: absolute;
        right: -25vw;
        width: 230px;
        z-index: 1;
        @media all and (min-width: 769px) and (max-width: 920px) {
          right: -225px;
        }
        @media #{$sp} {
          bottom: -18px;
          height: 261px;
          left: 52%;
          transform: translateX(-50%);
          width: 120px;
        }
      }
    }
    &__title {
      font-size: 40px;
      text-align: center;
      @media #{$sp} {
        font-size: 32px;
        font-weight: 300;
      }
    }
    &__widget {
      margin-top: 51px;
      position: relative;
      z-index: 2;
      @media #{$sp} {
        margin-top: 45px;
      }
    }
    &__item {
      &+.contact-check__item {
        margin-top: 26px;
      }
    }
    &__title-sub {
      display: block;
      font-size: 16px;
      font-weight: 700;
    }
    &__text {
      display: block;
      font-size: 14px;
      width: 100%;
      @media #{$sp} {
        font-size: 14px;
      }
    }
    &__submit {
      text-align: center;
      margin-top: 55px;
    }
    &__button {
      @include hoverlink;
      background: #3f9f28;
      color: #fff;
      font-size: 20px;
      letter-spacing: -1px;
      padding: 15px 110px;
      @media #{$sp} {
        padding: 15px;
        width: 100%;
      }
      @media all and (min-width: 320px) and (max-width: 374px) {
        padding: 15px 10px;
        font-size: 18px;
      }
    }
  }
}
