/*
  ----------------
  Top
  ----------------
*/

body.top {

  .hero {
    &__wrap {
      align-items: center;
      display: flex;
      position: relative;
      @media #{$sp} {
        padding-top: 19vw;
      }
    }
    &__bg {
      width: 100%;
      z-index: -1;
    }
    &__image {
      vertical-align: bottom;
      width: 100%;
    }
    &__box {
      position: absolute;
      top: 39%;
      left: 16.7%;
      @media #{$sp} {
        text-align: center;
        top: 26.5%;
        left: 50%;
        transform: translateX(-50%);
        width: 100vw;
      }
    }
    &__title {
      font-family: 'ヒラギノ角ゴシック';
      font-size: 4.1vw;
      font-weight: 600;
      letter-spacing: -2px;
      @media #{$sp} {
        font-size: 7.6vw;
        letter-spacing: 0;
      }
    }
    &__title-sub {
      font-size: 1vw;
      text-align: center;
      @media #{$sp} {
        font-size: 4vw;
        margin-top: 5px;
      }
    }
  }

  .section {
    padding: 120px 2% 105px;
    @media #{$sp} {
      padding: 80px 5% 80px;
    }
  }

  .title {
    color: $green;
    font-family: $open-sans;
    font-size: 37px;
    font-weight: bold;
    text-align: center;
  }

  .title-sub {
    color: $green;
    font-size: 19px;
    margin-top: 2px;
    font-weight: bold;
    text-align: center;
  }

  .title-line {
    border-top: 1px solid $green;
    height: 85px;
    text-align: center;
  }

  .title-box {
    display: inline-block;
    padding: 0 20px;
    transform: translateY(-32%);
  }

  .top-service {
    background: $gray;
    position: relative;

    &__wrap {
      @include container;
    }
    &__title-box {
      background: $gray;
    }
    &__title {

    }
    &__ttl-sub {

    }
    &__text {
      letter-spacing: 0.2px;
      line-height: 1.6;
      text-align: center;
      @media #{$sp} {
        margin-top: 10px;
        letter-spacing: -1px;
      }
    }
    &__contents {
      display: flex;
      flex-wrap: wrap;
      @media #{$pc} {
        justify-content: center;
        margin-top: 20px;
      }
      @media #{$sp} {
        margin-top: 60px;
      }
    }
    &__item {
      @include hoverlink;
      background: url(../img/top/service_develop.jpg) 50% / cover no-repeat;
      color: #fff;
      font-size: 30px;
      font-weight: bold;
      margin-top: 35px;
      padding: 100px 0;
      text-align: center;
      flex-basis: 48.6%;

      @media #{$sp} {
        background: url(../img/top/service_develop_sp.jpg) 50% / cover no-repeat;
        flex-basis: 100%;
        font-size: 5vw;
        margin-top: 0;
        padding: 16% 0;
        &:not(:first-child) {
          margin-top: 25px;
        }
      }

      &:nth-child(2) {
        background: url(../img/top/service_ses.jpg) 50% / cover no-repeat;
        @media #{$pc} {
          background: url(../img/top/service_ses_sp.jpg) 50% / cover no-repeat;
          margin-left: 20px;
        }
      }
      &:nth-child(3) {
        background: url(../img/top/service_shop.jpg) 50% / cover no-repeat;
        @media #{$pc} {
          background: url(../img/top/service_shop_sp.jpg) 50% / cover no-repeat;
          margin-top: 20px;
        }
      }
      &:nth-child(4) {
        background: url(../img/top/service_web.jpg) 50% / cover no-repeat;
        @media #{$pc} {
          background: url(../img/top/service_web_sp.jpg) 50% / cover no-repeat;
          margin-top: 20px;
          margin-left: 20px;
        }
      }
      &:nth-child(5) {
        background: url(../img/top/service_work.jpg) 50% / cover no-repeat;
        @media #{$pc} {
          background: url(../img/top/service_work_sp.jpg) 50% / cover no-repeat;
          margin-top: 20px;
          margin-left: 20px;
        }
      }
    }
    &__link {
      margin-top: 40px;
      position: relative;
      text-align: center;
      @media #{$sp} {
        margin-top: 5px;
      }

      &::before {
        position: absolute;
        display: block;
        content: "";
        background: url(../img/common/arrow_green.png) 50% / cover no-repeat;
        bottom: -6px;
        left: 50%;
        width: 402px;
        height: 16px;
        transform: translateX(-50%);
        @media #{$sp} {
          background-size: contain;
          height: 4vmin;
          width: 100%;
        }
      }
    }
    &__link-inner {
      @include hoverlink;
      color: $green;
      font-weight: bold;
      font-size: 30px;
      letter-spacing: -3px;
    }
  }

  .top-recruit {
    @media #{$sp} {
      padding-bottom: 100px;
    }
    &__wrap {
      @include container;
    }
    &__title-box {
      background: #fff;
    }
    &__title {}
    &__ttl-sub {}
    &__text {
      letter-spacing: 0.2px;
      line-height: 1.6;
      text-align: center;
      @media #{$sp} {
        margin-top: 3px;
        letter-spacing: -1px;
      }
    }
    &__contents {
      margin-top: 65px;
      @media #{$sp} {
        margin-top: 35px;
      }
    }
    &__item {
      background: linear-gradient(to right, #fff, #fff 33%, #3f9f28 33%);
      display: flex;
      padding-bottom: 30px;
      @media #{$sp} {
        background: none;
        flex-direction: column;
        padding-bottom: 0;
      }

      &:last-child {
        background: linear-gradient(to left, #fff, #fff 33%, #3f9f28 33%);
        margin-top: 80px;
        @media #{$sp} {
          background: none;
          margin-top: 75px;
        }
      }
    }
    &__image {
      margin-top: -30px;
      width: 100%;
      @media #{$pc} {
        max-width: 653px;
      }
      @media #{$sp} {
        margin-top: 0;
        padding-right: 6vmin;
      }

      &--reverse {
        @media #{$pc} {
          margin-left: 0;
          order: 2;
        }
      }
    }
    &__image-inner {
      vertical-align: bottom;
    }
    &__box {
      justify-content: center;
      color: #fff;
      display: flex;
      flex-direction: column;
      max-width: 280px;
      margin: 0 20px;
      width: 50%;
      @media #{$sp} {
        background: linear-gradient(to right, #fff, #fff 6%, #3f9f28 6%);
        margin: -15px 0 0;
        max-width: 100%;
        padding: 0 6% 9% 12%;
        width: 100%;
      }

      &--reverse {
        @media #{$pc} {
          order: 1;
        }
      }
    }
    &__item-title {
      font-weight: bold;
      margin-top: 15px;
      @media #{$sp} {
        font-size: 20px;
        margin-top: 30px;
      }
    }
    &__item-text {
      font-weight: bold;
      letter-spacing: -0.3px;
      line-height: 1.5;
      margin-top: 15px;
      @media #{$sp} {
        margin-top: 5px;
      }
    }
    &__link {
      margin-top: 35px;
      position: relative;
      text-align: center;
      @media #{$sp} {
        margin-top: 5px;
      }

      &::before {
        position: absolute;
        display: block;
        content: "";
        background: url(../img/common/arrow_white.png) 50% / contain no-repeat;
        bottom: -6px;
        left: 50%;
        width: 100%;
        height: 16px;
        transform: translateX(-50%);
        @media #{$sp} {
          background-size: contain;
          bottom: -10px;
        }
      }
    }
    &__link-inner {
      @include hoverlink;
      font-size: 20px;
    }
  }

  .top-about {
    background: $gray;
    padding-bottom: 188px;
    position: relative;
    @media #{$sp} {
      padding-top: 120px;
    }

    &::before {
      position: absolute;
      display: block;
      content: "";
      background: url(../img/top/about_leaf.png) 50% / cover no-repeat;
      bottom: -12px;
      left: 51%;
      width: 144px;
      height: 190px;
      transform: translateX(-50%);
      @media #{$sp} {
        left: 56%;
      }
    }
    &__wrap {
      @include container;
    }
    &__title-box {
      background: $gray;
    }
    &__title {}
    &__ttl-sub {}
    &__contents {
      margin-top: 5px;
    }
    &__item {
      border-top: 1px solid #4d4d4d;
      padding: 13px 0;

      &:last-child {
        border-bottom: 1px solid #4d4d4d;
      }
    }
    &__item-wrap {
      display: flex;
      align-items: baseline;
    }
    &__heading {
      flex-basis: 14.5%;
      padding-left: 40px;
      @media #{$sp} {
        flex-basis: 32%;
        padding-left: 0;
      }
    }
    &__detail {
      flex-basis: 68%;
      line-height: 1.6;
      @media #{$sp} {

      }
    }
    &__address {
      margin-top: 35px;
      @media #{$sp} {
        margin-top: 30px;
      }
    }
    &__address-title {
      font-size: 20px;
      text-align: center;
    }
    &__address-text {
      text-align: center;
      line-height: 1.6;
      margin-top: 5px;
      @media #{$sp} {
        letter-spacing: 0;
      }
    }
    &__map {
      margin-top: 13px;
      @media #{$sp} {
        margin: 15px calc(((100vw - 100%) / 2) * -1) 0;
      }
    }
    &__map-inner {
      width: 100%;
      height: 283px;
    }
  }

  .top-wantedly-story {
    background: $gray;
    padding-bottom: 40px;
    position: relative;
    @media #{$sp} {
      padding-top: 120px;
    }

    &__wrap {
      @include container;
    }

    &__title-box {
      background: $gray;
    }

    &__title {
    }

    &__title-sub {
    }

    &__contents {
      margin-top: 5px;
    }

    &__item {
      border-top: 1px solid #4d4d4d;
      padding: 13px 0;

      &:last-child {
        border-bottom: 1px solid #4d4d4d;
      }
    }

    &__item-wrap {
      display: flex;
      align-items: baseline;
    }

    &__item-wrap > a {
      width: 100%;
    }

    &__item-inner {
      display: flex;
      @media #{$sp} {
        display: table-cell;
      }
    }

    &__item-inner-img {
      width: 200px;
      height: auto;
      @media #{$sp} {
        width: 100%;
      }
    }

    &__item-inner-div {
      margin-left: 20px;
      width: auto;
      @media #{$sp} {
        margin-left: 0
      }
    }
  }

  .top-qiita-articles {
    background: $gray;
    padding-bottom: 188px;
    position: relative;
    @media #{$sp} {
      padding-top: 120px;
      padding-bottom: 290px;
    }

    &::before {
      position: absolute;
      display: block;
      content: "";
      background: url(../img/top/service_leaf.png) 50% / cover no-repeat;
      bottom: -45px;
      right: 2.1%;
      width: 369px;
      height: 504px;
      @media #{$sp} {
        bottom: -33px;
        right: 50%;
        height: 290px;
        width: 212px;
        transform: translateX(140px);
      }
    }

    &__wrap {
      @include container;
    }

    &__title-box {
      background: $gray;
    }

    &__title {
    }

    &__title-sub {
    }

    &__contents {
      margin-top: 5px;
    }

    &__item {
      border-top: 1px solid #4d4d4d;
      padding: 13px 0;

      &:last-child {
        border-bottom: 1px solid #4d4d4d;
      }
    }

    &__item:hover .content-title {
      color: #3f9f28;
    }

    &__item-wrap {
      display: flex;
      align-items: baseline;
    }

    &__item-wrap > a {
      width: 100%;
    }

    &__item-inner {
      display: flex;
      @media #{$sp} {
        display: table-cell;
      }
    }

    &__item-inner-div {
      margin-left: 20px;
      width: auto;
      @media #{$sp} {
        margin-left: 0
      }
    }
    .content-title {
      color: rgba(0,0,0,0.87);
      font-size: 18px;
      font-weight: 600;
      line-height: 1.5;
      word-break: break-all;
    }
  }

}