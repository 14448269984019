@charset "UTF-8";
* {
  box-sizing: border-box; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased; }

body {
  margin: 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal;
  font-size: inherit; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

img {
  border: 0;
  max-width: 100%;
  height: auto;
  backface-visibility: hidden; }

ul,
ol,
dl,
dt,
dd {
  padding: 0;
  margin: 0; }

li, ol {
  list-style: none; }

a {
  text-decoration: none;
  display: inline-block;
  color: inherit;
  cursor: pointer; }

p {
  margin-top: 0;
  margin-bottom: 0; }

input[type="submit"],
input[type="reset"],
input[type="button"],
button {
  background: none;
  border: 0;
  color: inherit;
  /* cursor: default; */
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-appearance: button;
  /* for input */
  -webkit-user-select: none;
  /* for button */
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer; }

button:focus {
  outline: 0; }

input::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0;
  padding: 0; }

[role="button"] {
  color: inherit;
  cursor: default;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  white-space: pre;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none; }

@media all and (min-width: 769px) {
  a[href^=tel] {
    pointer-events: none; } }

/*
  ----------------
  Base styles
  ----------------
*/
body {
  letter-spacing: 0.04em;
  font-weight: 400; }
  @media all and (max-width: 768px) {
    body {
      font-size: 0.75rem; } }

img {
  max-width: 100%; }

@media all and (min-width: 769px) {
  a[href^="tel:"] {
    pointer-events: none; } }

@media all and (max-width: 768px) {
  .container {
    padding-left: 5vmin;
    padding-right: 5vmin; } }

/*
  ----------------
  Base font settings
  ----------------
*/
html {
  font-size: 62.5%; }

body {
  font-size: 1.6rem;
  /* 16px*/
  line-height: 2; }

h1 {
  font-size: 36px;
  /* IE8以下とAndroid4.3以下用フォールバック */
  font-size: calc(2.4rem + ((1vw - 0.64rem) * 2.1429));
  /* 24px~36pxで可変*/
  line-height: 1.3; }

h2 {
  font-size: 24px;
  /* IE8以下とAndroid4.3以下用フォールバック */
  font-size: calc(2rem + ((1vw - 0.64rem) * 0.7143));
  /* 20px~24pxで可変*/
  line-height: 1.3;
  font-weight: 500; }

h3 {
  font-size: 20px;
  /* IE8以下とAndroid4.3以下用フォールバック */
  font-size: calc(1.5rem + ((1vw - 0.64rem) * 0.7143));
  /* 20px~24pxで可変*/
  line-height: 1.3;
  font-weight: 500; }

@media (min-width: 1200px) {
  /* 1200px以上*/
  h1 {
    font-size: 3.6rem;
    /* 36px*/ }
  h2 {
    font-size: 3.5rem;
    /* 24px*/ }
  h3 {
    font-size: 2rem;
    /* 24px*/ } }

@media screen and (max-width: 640px) {
  /* 640px以下*/
  body {
    line-height: 2; }
  h1 {
    font-size: 2.4rem;
    /* 24px*/ }
  h2 {
    font-size: 2rem;
    /* 20px*/ }
  h3 {
    font-size: 1.5rem;
    /* 15px*/ } }

/*
  ----------------
  文章のスタイル設定
  ----------------
*/
body {
  font-family: "ヒラギノ角ゴシック", "Noto Sans Japanese", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "游ゴシック", "Yu Gothic", "游ゴシック体", "YuGothic", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-weight: 300; }

/*
  ----------------
  ユーティリティー
  ----------------
*/
@media all and (min-width: 769px) {
  .sp-only {
    display: none !important; } }

@media all and (max-width: 768px) {
  .pc-only {
    display: none !important; } }

/*
  ----------------
  Animations
  ----------------
*/
/*
  ----------------
  Header
  ----------------
*/
body.is-open {
  overflow: hidden; }

.header {
  background: #fff;
  position: fixed;
  width: 100%;
  z-index: 10; }
  @media all and (min-width: 769px) {
    .top .header {
      background: transparent; } }
  @media all and (max-width: 768px) {
    .header {
      background: #fff; } }
  .header__wrap {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    position: relative; }
    @media all and (min-width: 769px) {
      .top .header__wrap {
        padding: 20px; } }
    @media all and (max-width: 768px) {
      .header__wrap {
        padding: 3.8vw; } }
  .header__checkbox {
    display: none; }
  .header__icon {
    cursor: pointer;
    display: block;
    width: 8.5vmin;
    height: 8.5vmin;
    position: fixed;
    right: 8%;
    z-index: 4; }
  .header__icon-parts, .header__icon-parts:before, .header__icon-parts:after {
    background-color: #3f9f28;
    display: block;
    width: 100%;
    height: 2px;
    position: absolute;
    top: 0;
    right: 0%;
    left: 0;
    bottom: 0;
    margin: auto; }
  .header__icon-parts:before, .header__icon-parts:after {
    content: " "; }
  .header__icon-parts:before {
    top: 6vmin; }
  .header__icon-parts:after {
    top: -6vmin; }
  .header__overlay {
    background: #000;
    opacity: 0;
    pointer-events: none;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 20vw;
    right: 0;
    z-index: 2; }
  .header__logo-link {
    transition: 0.2s ease-in-out;
    backface-visibility: hidden;
    cursor: pointer; }
    .header__logo-link:hover {
      opacity: 0.7; }
    .header__logo-link::before, .header__logo-link::after {
      backface-visibility: hidden; }
  @media all and (max-width: 768px) {
    .header__image {
      height: 11vmin; } }
  @media all and (max-width: 768px) {
    .header__menu {
      overflow-y: auto;
      opacity: 0;
      position: fixed;
      top: 19.8vw;
      right: -100vw;
      width: 100%;
      z-index: 3; } }
  .header__list {
    align-items: center;
    display: flex; }
    @media all and (max-width: 768px) {
      .header__list {
        background: #fff;
        flex-direction: column; } }
  @media all and (max-width: 768px) {
    .header__item {
      border-top: 1px solid #2d2d2d;
      width: 100%; } }
  .header__link {
    transition: 0.2s ease-in-out;
    backface-visibility: hidden;
    cursor: pointer;
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: #3f9f28;
    padding: 0 10px; }
    .header__link:hover {
      opacity: 0.7; }
    .header__link::before, .header__link::after {
      backface-visibility: hidden; }
    @media all and (max-width: 768px) {
      .header__link {
        font-size: 7.3vw;
        padding: 3px 10px;
        text-align: center;
        width: 100%; } }
    .header__link--white {
      background: #fff;
      border: 1px solid #3f9f28;
      font-family: "ヒラギノ角ゴシック", "Noto Sans Japanese", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "游ゴシック", "Yu Gothic", "游ゴシック体", "YuGothic", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
      font-size: 16px;
      margin-left: 27px;
      padding: 3px 29px; }
  .header__checkbox:checked ~ .header__icon .header__icon-parts {
    background: transparent; }
  .header__checkbox:checked ~ .header__icon .header__icon-parts:before {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 0; }
  .header__checkbox:checked ~ .header__icon .header__icon-parts:after {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 0; }
  .header__checkbox:checked ~ .header__overlay {
    opacity: 0.3;
    pointer-events: auto; }
  .header__checkbox:checked ~ .header__menu {
    opacity: 1;
    right: 0; }
  .header__icon-parts, .header__icon-parts:after, .header__icon-parts:before, .header__overlay, .header__menu {
    -webkit-transition: all .7s;
    transition: all .7s; }

/*
  ----------------
  Footer
  ----------------
*/
.footer {
  background: #f2f2f2; }
  .footer__wrap {
    max-width: 980px;
    margin: 0 auto;
    position: relative;
    padding: 40px 2% 50px; }
    @media all and (max-width: 768px) {
      .footer__wrap {
        padding: 30px 2% 50px; } }
  .footer__nav {
    display: flex; }
    @media all and (max-width: 768px) {
      .footer__nav {
        flex-direction: column; } }
  @media all and (max-width: 768px) {
    .footer__brand {
      text-align: center; } }
  .footer__image {
    transition: 0.2s ease-in-out;
    backface-visibility: hidden;
    cursor: pointer;
    width: 200px; }
    .footer__image:hover {
      opacity: 0.7; }
    .footer__image::before, .footer__image::after {
      backface-visibility: hidden; }
  .footer__menu {
    align-items: flex-end;
    display: flex; }
    @media all and (min-width: 769px) {
      .footer__menu {
        margin-left: 40px;
        padding-bottom: 12px; } }
    @media all and (max-width: 768px) {
      .footer__menu {
        justify-content: center;
        margin-top: 8px; } }
  .footer__item {
    font-family: "Open Sans", sans-serif;
    line-height: 1em;
    padding: 0 20px;
    position: relative; }
    .footer__item:not(:first-child)::before {
      position: absolute;
      display: block;
      content: "|";
      top: 0;
      left: 0px;
      font-size: 13px; }
  .footer__link {
    transition: 0.2s ease-in-out;
    backface-visibility: hidden;
    cursor: pointer;
    font-size: 15px;
    letter-spacing: 0; }
    .footer__link:hover {
      opacity: 0.7; }
    .footer__link::before, .footer__link::after {
      backface-visibility: hidden; }
  .footer__box {
    display: flex;
    justify-content: flex-end;
    margin-top: 37px; }
    @media all and (max-width: 768px) {
      .footer__box {
        display: block;
        text-align: center;
        flex-direction: column; } }
  .footer__policy {
    transition: 0.2s ease-in-out;
    backface-visibility: hidden;
    cursor: pointer;
    color: #666;
    border-bottom: 1px solid #666;
    height: 1.6em; }
    .footer__policy:hover {
      opacity: 0.7; }
    .footer__policy::before, .footer__policy::after {
      backface-visibility: hidden; }
    @media all and (max-width: 768px) {
      .footer__policy {
        display: content; } }
  .footer__security-policy {
    transition: 0.2s ease-in-out;
    backface-visibility: hidden;
    cursor: pointer;
    margin-left: 20px;
    color: #666;
    border-bottom: 1px solid #666;
    height: 1.6em; }
    .footer__security-policy:hover {
      opacity: 0.7; }
    .footer__security-policy::before, .footer__security-policy::after {
      backface-visibility: hidden; }
    @media all and (max-width: 768px) {
      .footer__security-policy {
        display: content; } }
  .footer__copy {
    color: #666;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    letter-spacing: 0.4px; }
    @media all and (min-width: 769px) {
      .footer__copy {
        margin-left: 40px; } }

/*
  ----------------
  Headings
  ----------------
*/
/*
  ----------------
  パンくずのスタイル設定
  ----------------
*/
/*
  ----------------
  ボタン
  ----------------
*/
/*
  ----------------
  Contact check
  ----------------
*/
body.policy .policy {
  overflow: hidden; }
  body.policy .policy__wrap {
    max-width: 1020px;
    margin: 0 auto;
    padding: 125px 20px 100px;
    position: relative; }
    @media all and (max-width: 768px) {
      body.policy .policy__wrap {
        padding: 34vw 20px 70px; } }
  body.policy .policy__title {
    font-size: 40px;
    text-align: center; }
    @media all and (max-width: 768px) {
      body.policy .policy__title {
        font-size: 32px;
        font-weight: 300; } }
  body.policy .policy__contents {
    margin-top: 40px; }
  body.policy .policy__title-sub {
    display: block;
    font-size: 20px;
    font-weight: 700; }
    body.policy .policy__title-sub + .policy__text {
      margin-top: 10px; }
  body.policy .policy__text {
    display: block;
    letter-spacing: -1.5px;
    line-height: 1.7;
    width: 100%; }
    body.policy .policy__text + .policy__title-sub {
      margin-top: 60px; }

/*
  ----------------
  Contact check
  ----------------
*/
body.security-policy .security-policy {
  overflow: hidden; }
  body.security-policy .security-policy__wrap {
    max-width: 1020px;
    margin: 0 auto;
    padding: 125px 20px 100px;
    position: relative; }
    @media all and (max-width: 768px) {
      body.security-policy .security-policy__wrap {
        padding: 34vw 20px 70px; } }
  body.security-policy .security-policy__title {
    font-size: 40px;
    text-align: center; }
    @media all and (max-width: 768px) {
      body.security-policy .security-policy__title {
        font-size: 32px;
        font-weight: 300; } }
  body.security-policy .security-policy__contents {
    margin-top: 40px; }
  body.security-policy .security-policy__title-sub {
    display: block;
    font-size: 20px;
    font-weight: 700; }
    body.security-policy .security-policy__title-sub + .security-policy__text {
      margin-top: 10px; }
  body.security-policy .security-policy__text {
    display: block;
    letter-spacing: -1.5px;
    line-height: 1.7;
    width: 100%; }
    body.security-policy .security-policy__text + .security-policy__title-sub {
      margin-top: 60px; }
    body.security-policy .security-policy__text + .security-policy__indent {
      padding-left: 1em;
      text-indent: -1em; }
  body.security-policy .security-policy__update-list {
    text-align: right; }

/*
  ----------------
  Contact done
  ----------------
*/
body.done .contact-done {
  overflow: hidden; }
  body.done .contact-done__wrap {
    max-width: 640px;
    margin: 0 auto;
    padding: 125px 0 480px;
    position: relative; }
    @media all and (max-width: 768px) {
      body.done .contact-done__wrap {
        padding: 34vw 20px 270px; } }
    body.done .contact-done__wrap::before {
      background: url(../img/contact/leaf.png) 50%/cover no-repeat;
      bottom: -45px;
      content: "";
      display: block;
      height: 501px;
      position: absolute;
      right: -25vw;
      width: 230px;
      z-index: 1; }
      @media all and (min-width: 769px) and (max-width: 920px) {
        body.done .contact-done__wrap::before {
          right: -225px; } }
      @media all and (max-width: 768px) {
        body.done .contact-done__wrap::before {
          bottom: -18px;
          height: 261px;
          left: 52%;
          transform: translateX(-50%);
          width: 120px; } }
  body.done .contact-done__title {
    font-size: 40px;
    text-align: center; }
    @media all and (max-width: 768px) {
      body.done .contact-done__title {
        font-size: 32px;
        font-weight: 300; } }
  body.done .contact-done__widget {
    margin-top: 50px;
    position: relative;
    z-index: 2; }
    @media all and (max-width: 768px) {
      body.done .contact-done__widget {
        margin-top: 35px; } }
  body.done .contact-done__text {
    display: block;
    font-size: 14px;
    width: 100%; }
    @media all and (max-width: 768px) {
      body.done .contact-done__text {
        font-size: 14px; } }
  body.done .contact-done__submit {
    text-align: center;
    margin-top: 35px; }
  body.done .contact-done__button {
    transition: 0.2s ease-in-out;
    backface-visibility: hidden;
    cursor: pointer;
    background: #3f9f28;
    color: #fff;
    font-size: 20px;
    letter-spacing: -1px;
    padding: 10px 150px; }
    body.done .contact-done__button:hover {
      opacity: 0.7; }
    body.done .contact-done__button::before, body.done .contact-done__button::after {
      backface-visibility: hidden; }
    @media all and (max-width: 768px) {
      body.done .contact-done__button {
        padding: 10px;
        width: 100%; } }
    @media all and (min-width: 320px) and (max-width: 374px) {
      body.done .contact-done__button {
        padding: 15px 10px;
        font-size: 18px; } }

/*
  ----------------
  Contact check
  ----------------
*/
body.check .contact-check {
  overflow: hidden; }
  body.check .contact-check__wrap {
    max-width: 640px;
    margin: 0 auto;
    padding: 125px 0 150px;
    position: relative; }
    @media all and (max-width: 768px) {
      body.check .contact-check__wrap {
        padding: 34vw 20px 275px; } }
    body.check .contact-check__wrap::before {
      background: url(../img/contact/leaf.png) 50%/cover no-repeat;
      bottom: -45px;
      content: "";
      display: block;
      height: 501px;
      position: absolute;
      right: -25vw;
      width: 230px;
      z-index: 1; }
      @media all and (min-width: 769px) and (max-width: 920px) {
        body.check .contact-check__wrap::before {
          right: -225px; } }
      @media all and (max-width: 768px) {
        body.check .contact-check__wrap::before {
          bottom: -18px;
          height: 261px;
          left: 52%;
          transform: translateX(-50%);
          width: 120px; } }
  body.check .contact-check__title {
    font-size: 40px;
    text-align: center; }
    @media all and (max-width: 768px) {
      body.check .contact-check__title {
        font-size: 32px;
        font-weight: 300; } }
  body.check .contact-check__widget {
    margin-top: 51px;
    position: relative;
    z-index: 2; }
    @media all and (max-width: 768px) {
      body.check .contact-check__widget {
        margin-top: 45px; } }
  body.check .contact-check__item + .contact-check__item {
    margin-top: 26px; }
  body.check .contact-check__title-sub {
    display: block;
    font-size: 16px;
    font-weight: 700; }
  body.check .contact-check__text {
    display: block;
    font-size: 14px;
    width: 100%; }
    @media all and (max-width: 768px) {
      body.check .contact-check__text {
        font-size: 14px; } }
  body.check .contact-check__submit {
    text-align: center;
    margin-top: 55px; }
  body.check .contact-check__button {
    transition: 0.2s ease-in-out;
    backface-visibility: hidden;
    cursor: pointer;
    background: #3f9f28;
    color: #fff;
    font-size: 20px;
    letter-spacing: -1px;
    padding: 15px 110px; }
    body.check .contact-check__button:hover {
      opacity: 0.7; }
    body.check .contact-check__button::before, body.check .contact-check__button::after {
      backface-visibility: hidden; }
    @media all and (max-width: 768px) {
      body.check .contact-check__button {
        padding: 15px;
        width: 100%; } }
    @media all and (min-width: 320px) and (max-width: 374px) {
      body.check .contact-check__button {
        padding: 15px 10px;
        font-size: 18px; } }

/*
  ----------------
  Contact
  ----------------
*/
body.contact .contact-form {
  overflow: hidden; }
  body.contact .contact-form__wrap {
    max-width: 640px;
    margin: 0 auto;
    padding: 125px 0 100px;
    position: relative; }
    @media all and (max-width: 768px) {
      body.contact .contact-form__wrap {
        padding: 36vw 20px 270px; } }
    body.contact .contact-form__wrap::before {
      background: url(../img/contact/leaf.png) 50%/cover no-repeat;
      bottom: -45px;
      content: "";
      display: block;
      height: 501px;
      position: absolute;
      right: -25vw;
      width: 230px;
      z-index: 1; }
      @media all and (min-width: 769px) and (max-width: 920px) {
        body.contact .contact-form__wrap::before {
          right: -225px; } }
      @media all and (max-width: 768px) {
        body.contact .contact-form__wrap::before {
          bottom: -18px;
          height: 261px;
          left: 52%;
          transform: translateX(-50%);
          width: 120px; } }
  body.contact .contact-form__title {
    font-size: 40px;
    text-align: center; }
    @media all and (max-width: 768px) {
      body.contact .contact-form__title {
        font-size: 32px;
        font-weight: 300; } }
  body.contact .contact-form__widget {
    margin-top: 35px;
    position: relative;
    z-index: 2; }
  body.contact .contact-form__item + .contact-form__item {
    margin-top: 11px; }
  body.contact .contact-form__title-sub {
    display: block;
    font-weight: 600; }
  body.contact .contact-form__required {
    color: #ff0000;
    font-weight: normal; }
  body.contact .contact-form__input {
    appearance: none;
    background: #f4f7f4;
    border: none;
    border-radius: 4px;
    display: block;
    font-size: 14px;
    outline: none;
    padding: 13px 10px;
    width: 100%; }
    @media all and (max-width: 768px) {
      body.contact .contact-form__input {
        font-size: 14px; } }
    body.contact .contact-form__input::-ms-expand {
      display: none; }
    body.contact .contact-form__input--select {
      cursor: pointer; }
    body.contact .contact-form__input--text {
      height: 110px;
      resize: none;
      overflow: auto; }
  body.contact .contact-form__input-arrow {
    position: relative; }
    body.contact .contact-form__input-arrow::before {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 6px solid #666666;
      content: '';
      pointer-events: none;
      position: absolute;
      right: 16px;
      transform: translateY(-50%);
      top: 50%;
      width: 0; }
  body.contact .contact-form__policy {
    margin-top: 28px; }
  body.contact .contact-form__policy-title {
    font-size: 16px; }
  body.contact .contact-form__policy-text {
    background: #fff;
    border: 1px solid #ddd;
    font-size: 14px;
    height: 12em;
    letter-spacing: -1px;
    line-height: 1.8;
    overflow: auto;
    margin-top: 8px;
    padding: 12px 20px; }
  body.contact .contact-form__submit {
    text-align: center;
    margin-top: 40px; }
  body.contact .contact-form__button {
    transition: 0.2s ease-in-out;
    backface-visibility: hidden;
    cursor: pointer;
    background: #3f9f28;
    color: #fff;
    font-size: 20px;
    letter-spacing: -1px;
    padding: 15px 65px; }
    body.contact .contact-form__button:hover {
      opacity: 0.7; }
    body.contact .contact-form__button::before, body.contact .contact-form__button::after {
      backface-visibility: hidden; }
    @media all and (max-width: 768px) {
      body.contact .contact-form__button {
        padding: 15px;
        width: 100%; } }
    @media all and (min-width: 320px) and (max-width: 374px) {
      body.contact .contact-form__button {
        padding: 15px 10px;
        font-size: 18px; } }

/*
  ----------------
  Service
  ----------------
*/
body.service .heading__wrap {
  align-items: center;
  display: flex;
  padding-top: 80px;
  position: relative; }
  @media all and (max-width: 768px) {
    body.service .heading__wrap {
      padding-top: 21vw; } }

body.service .heading__bg {
  width: 100%;
  z-index: -1; }

body.service .heading__image {
  vertical-align: bottom;
  width: 100%; }

body.service .heading__box {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-top: 68px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; }
  @media all and (max-width: 768px) {
    body.service .heading__box {
      text-align: center;
      top: 0;
      left: 0;
      padding-top: 17vw; } }

body.service .heading__title {
  font-family: "Open Sans", sans-serif;
  color: #fff;
  font-size: 60px;
  font-weight: bold;
  letter-spacing: -2px; }
  @media all and (max-width: 768px) {
    body.service .heading__title {
      font-size: 56px;
      letter-spacing: 0; } }

body.service .heading__title-sub {
  font-size: 1vw;
  text-align: center; }
  @media all and (max-width: 768px) {
    body.service .heading__title-sub {
      font-size: 4vw;
      margin-top: 5px; } }

body.service .title {
  font-size: 40px;
  font-weight: 300;
  text-align: center; }
  @media all and (max-width: 768px) {
    body.service .title {
      font-size: 32px; } }

body.service .service-contents__wrap {
  padding-top: 50px; }

body.service .service-contents__box {
  display: flex; }
  @media all and (min-width: 769px) {
    body.service .service-contents__box {
      height: 38vw;
      max-height: 730px;
      min-height: 420px; } }
  @media all and (max-width: 768px) {
    body.service .service-contents__box {
      display: block; } }

body.service .service-contents__image {
  background: url(../img/service/contents_first.jpg) 0 50%/cover no-repeat;
  flex-basis: 50%; }
  @media all and (max-width: 768px) {
    body.service .service-contents__image {
      height: 75.8vw; } }
  body.service .service-contents__image--reverse {
    background: url(../img/service/contents_second.jpg) 50%/cover no-repeat; }
    @media all and (min-width: 769px) {
      body.service .service-contents__image--reverse {
        order: 2; } }

body.service .service-contents__image-inner {
  vertical-align: bottom; }

body.service .service-contents__inner {
  flex-basis: 50%;
  padding: 2vw 0 2vw 2vw; }
  @media all and (max-width: 768px) {
    body.service .service-contents__inner {
      flex-basis: 100%;
      padding: 20px; } }
  @media all and (min-width: 769px) {
    body.service .service-contents__inner--reverse {
      order: 1;
      padding: 2vw 2vw 2vw 0; } }

body.service .service-contents__inner-bg {
  align-items: center;
  background: #f4f7f4;
  display: flex;
  height: 100%;
  justify-content: center; }

body.service .service-contents__inner-wrap {
  padding: 0 15px 15px 15px;
  text-align: center; }
  @media all and (max-width: 768px) {
    body.service .service-contents__inner-wrap {
      margin: 0;
      padding: 20px; } }

body.service .service-contents__title {
  letter-spacing: -5px;
  margin-top: .8vw; }
  @media all and (min-width: 1600px) {
    body.service .service-contents__title {
      font-size: 40px; } }
  @media all and (min-width: 768px) and (max-width: 1599px) {
    body.service .service-contents__title {
      font-size: 30px;
      letter-spacing: -3px; } }
  @media all and (max-width: 768px) {
    body.service .service-contents__title {
      font-size: 32px; } }
  @media all and (min-width: 320px) and (max-width: 374px) {
    body.service .service-contents__title {
      font-size: 31px; } }

body.service .service-contents__list {
  display: inline-block;
  margin-top: 1.3vw;
  padding-left: 30px; }
  @media all and (max-width: 768px) {
    body.service .service-contents__list {
      display: block;
      margin-top: 5px;
      padding-left: 15px; } }

body.service .service-contents__item {
  font-weight: 600;
  text-align: left;
  line-height: 2.2;
  list-style: disc; }
  @media all and (min-width: 768px) and (max-width: 1000px) {
    body.service .service-contents__item {
      line-height: 1.3; } }
  @media all and (max-width: 768px) {
    body.service .service-contents__item {
      letter-spacing: -0.5px;
      line-height: 1.5; } }

body.service .service-contents__text {
  font-size: calc(13px + ((1vw - 12px) * 0.7));
  letter-spacing: -1px;
  line-height: 1.5;
  margin-top: 1vw; }
  @media all and (min-width: 1500px) {
    body.service .service-contents__text {
      font-size: 16px; } }
  @media all and (min-width: 768px) and (max-width: 1200px) {
    body.service .service-contents__text {
      font-size: 13px; } }
  @media all and (max-width: 768px) {
    body.service .service-contents__text {
      font-size: 16px;
      margin-top: 10px;
      padding: 0; } }

body.service .service-consult {
  background: #f2f2f2; }
  body.service .service-consult__wrap {
    padding: 95px 20px 100px; }
    @media all and (min-width: 769px) {
      body.service .service-consult__wrap {
        max-width: 1020px;
        margin: 0 auto; } }
    @media all and (max-width: 768px) {
      body.service .service-consult__wrap {
        padding: 100px 20px; } }
  body.service .service-consult__title {
    letter-spacing: -3px; }
  body.service .service-consult__lead {
    line-height: 1.7;
    margin-top: 25px; }
    @media all and (min-width: 769px) {
      body.service .service-consult__lead {
        text-align: center; } }
    @media all and (max-width: 768px) {
      body.service .service-consult__lead {
        letter-spacing: -1.6px; } }
  body.service .service-consult__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 35px; }
    @media all and (max-width: 768px) {
      body.service .service-consult__list {
        margin-top: 50px; } }
  body.service .service-consult__item {
    flex-basis: 48.6%; }
    @media all and (min-width: 769px) {
      body.service .service-consult__item:nth-child(2n) {
        margin-left: 20px; }
      body.service .service-consult__item:nth-child(n+3) {
        margin-top: 35px; } }
    @media all and (max-width: 768px) {
      body.service .service-consult__item {
        flex-basis: 100%; }
        body.service .service-consult__item + .service-consult__item {
          margin-top: 60px; } }
  body.service .service-consult__image-inner {
    width: 100%; }
  body.service .service-consult__title-sub {
    font-size: 20px;
    font-weight: 600; }
  body.service .service-consult__text {
    letter-spacing: -1.3px;
    line-height: 1.6;
    margin-top: 3px; }

body.service .service-example__wrap {
  padding: 90px 20px 100px; }
  @media all and (min-width: 769px) {
    body.service .service-example__wrap {
      max-width: 1020px;
      margin: 0 auto; } }
  @media all and (max-width: 768px) {
    body.service .service-example__wrap {
      padding: 100px 20px; } }

body.service .service-example__contents {
  margin-top: 35px; }
  @media all and (min-width: 769px) {
    body.service .service-example__contents {
      display: flex; } }
  @media all and (max-width: 768px) {
    body.service .service-example__contents {
      margin-top: 5px; } }

body.service .service-example__box {
  flex-basis: 50%; }
  @media all and (min-width: 769px) {
    body.service .service-example__box:last-child {
      margin-left: 20px; } }
  @media all and (max-width: 768px) {
    body.service .service-example__box:last-child {
      margin-top: 35px; } }

body.service .service-example__image-inner {
  width: 100%; }

body.service .service-example__title-sub {
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: bold;
  text-align: center; }
  @media all and (max-width: 768px) {
    body.service .service-example__title-sub {
      font-size: 17px;
      text-align: left;
      margin-top: -15px; } }

body.service .service-example__link {
  margin-top: 80px;
  position: relative;
  text-align: center; }
  @media all and (max-width: 768px) {
    body.service .service-example__link {
      margin-top: 85px; } }
  body.service .service-example__link::before {
    position: absolute;
    display: block;
    content: "";
    background: url(../img/common/arrow_green.png) 50%/cover no-repeat;
    bottom: -6px;
    left: 50%;
    width: 402px;
    height: 16px;
    transform: translateX(-50%); }
    @media all and (max-width: 768px) {
      body.service .service-example__link::before {
        background-size: contain;
        height: 4vmin;
        width: 100%; } }

body.service .service-example__link-inner {
  transition: 0.2s ease-in-out;
  backface-visibility: hidden;
  cursor: pointer;
  color: #3f9f28;
  font-size: 30px;
  letter-spacing: -1px; }
  body.service .service-example__link-inner:hover {
    opacity: 0.7; }
  body.service .service-example__link-inner::before, body.service .service-example__link-inner::after {
    backface-visibility: hidden; }
  @media all and (max-width: 768px) {
    body.service .service-example__link-inner {
      width: 100%; } }

/*
  ----------------
  Work page
  ----------------
*/
body.work .heading__wrap {
  align-items: center;
  display: flex;
  padding-top: 80px;
  position: relative; }
  @media all and (max-width: 768px) {
    body.work .heading__wrap {
      padding-top: 21vw; } }

body.work .heading__bg {
  width: 100%;
  z-index: -1; }

body.work .heading__image {
  vertical-align: bottom;
  width: 100%; }

body.work .heading__box {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-top: 68px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; }
  @media all and (max-width: 768px) {
    body.work .heading__box {
      text-align: center;
      top: 0;
      left: 0;
      padding-top: 17vw; } }

body.work .heading__title {
  font-family: "Open Sans", sans-serif;
  color: #fff;
  font-size: 60px;
  font-weight: bold;
  letter-spacing: -2px; }
  @media all and (max-width: 768px) {
    body.work .heading__title {
      font-size: 56px;
      letter-spacing: 0; } }

body.work .heading__title-sub {
  font-size: 1vw;
  text-align: center; }
  @media all and (max-width: 768px) {
    body.work .heading__title-sub {
      font-size: 4vw;
      margin-top: 5px; } }

body.work .work-main {
  background: #f2f2f2;
  position: relative;
  padding: 120px 2%; }
  @media all and (max-width: 768px) {
    body.work .work-main {
      padding: 120px 5%; } }
  body.work .work-main__wrap {
    max-width: 980px;
    margin: 0 auto;
    position: relative; }
  body.work .work-main__contents {
    margin-top: 5px; }
  body.work .work-main__item {
    border-top: 1px solid #4d4d4d;
    padding: 13px 0; }
    body.work .work-main__item:last-child {
      border-bottom: 1px solid #4d4d4d; }
  body.work .work-main__item-wrap {
    display: flex;
    align-items: baseline; }
  body.work .work-main__heading {
    flex-basis: 14.5%;
    padding-left: 40px; }
    @media all and (max-width: 768px) {
      body.work .work-main__heading {
        flex-basis: 32%;
        padding-left: 0; } }
  body.work .work-main__detail {
    flex-basis: 68%;
    line-height: 1.6; }
  body.work .work-main__address {
    margin-top: 35px; }
    @media all and (max-width: 768px) {
      body.work .work-main__address {
        margin-top: 30px; } }
  body.work .work-main__address-title {
    font-size: 20px;
    text-align: center; }
  body.work .work-main__address-text {
    text-align: center;
    line-height: 1.6;
    margin-top: 5px; }
    @media all and (max-width: 768px) {
      body.work .work-main__address-text {
        letter-spacing: 0; } }
  body.work .work-main__map {
    margin-top: 13px; }
    @media all and (max-width: 768px) {
      body.work .work-main__map {
        margin: 15px calc(((100vw - 100%) / 2) * -1) 0; } }
  body.work .work-main__map-inner {
    width: 100%;
    height: 283px; }

body.work .work-contact {
  overflow: hidden; }
  body.work .work-contact__wrap {
    max-width: 640px;
    margin: 0 auto;
    padding: 125px 0 200px;
    position: relative; }
    @media all and (max-width: 768px) {
      body.work .work-contact__wrap {
        padding: 80px 5% 130px; } }
    body.work .work-contact__wrap::before {
      position: absolute;
      display: block;
      content: "";
      background: url(../img/top/about_leaf.png) 50%/cover no-repeat;
      bottom: -12px;
      left: 51%;
      width: 144px;
      height: 190px;
      transform: translateX(-50%); }
      @media all and (min-width: 769px) and (max-width: 920px) {
        body.work .work-contact__wrap::before {
          right: -225px; } }
      @media all and (max-width: 768px) {
        body.work .work-contact__wrap::before {
          left: 56%;
          height: 135px;
          width: 100px; } }
  body.work .work-contact__title {
    font-size: 40px;
    text-align: center; }
    @media all and (max-width: 768px) {
      body.work .work-contact__title {
        font-size: 32px;
        font-weight: 300; } }
  body.work .work-contact__widget {
    margin-top: 35px;
    position: relative;
    z-index: 2; }
  body.work .work-contact__item + .contact-form__item {
    margin-top: 11px; }
  body.work .work-contact__check {
    background: #f4f7f4;
    border-radius: 4px;
    padding: 10px; }
  body.work .work-contact__check-box {
    margin-left: 5px; }
  body.work .work-contact__title-sub {
    display: block;
    font-weight: 600; }
  body.work .work-contact__required {
    color: #ff0000;
    font-weight: normal; }
  body.work .work-contact__input {
    appearance: none;
    background: #f4f7f4;
    border: none;
    border-radius: 4px;
    display: block;
    font-size: 14px;
    outline: none;
    padding: 13px 10px;
    width: 100%; }
    @media all and (max-width: 768px) {
      body.work .work-contact__input {
        font-size: 14px; } }
    body.work .work-contact__input::-ms-expand {
      display: none; }
    body.work .work-contact__input--select {
      cursor: pointer; }
    body.work .work-contact__input--text {
      height: 110px;
      resize: none;
      overflow: auto; }
  body.work .work-contact__input-arrow {
    position: relative; }
    body.work .work-contact__input-arrow::before {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 6px solid #666666;
      content: '';
      pointer-events: none;
      position: absolute;
      right: 16px;
      transform: translateY(-50%);
      top: 50%;
      width: 0; }
  body.work .work-contact__policy {
    margin-top: 28px; }
  body.work .work-contact__policy-title {
    font-size: 16px; }
  body.work .work-contact__policy-text {
    background: #fff;
    border: 1px solid #ddd;
    font-size: 14px;
    height: 12em;
    letter-spacing: -1px;
    line-height: 1.8;
    overflow: auto;
    margin-top: 8px;
    padding: 12px 20px; }
  body.work .work-contact__submit {
    text-align: center;
    margin-top: 40px; }
  body.work .work-contact__button {
    transition: 0.2s ease-in-out;
    backface-visibility: hidden;
    cursor: pointer;
    background: #3f9f28;
    color: #fff;
    font-size: 20px;
    letter-spacing: -1px;
    padding: 15px 65px; }
    body.work .work-contact__button:hover {
      opacity: 0.7; }
    body.work .work-contact__button::before, body.work .work-contact__button::after {
      backface-visibility: hidden; }
    @media all and (max-width: 768px) {
      body.work .work-contact__button {
        padding: 15px;
        width: 100%; } }
    @media all and (min-width: 320px) and (max-width: 374px) {
      body.work .work-contact__button {
        padding: 15px 10px;
        font-size: 18px; } }

/*
  ----------------
  Top
  ----------------
*/
body.top .hero__wrap {
  align-items: center;
  display: flex;
  position: relative; }
  @media all and (max-width: 768px) {
    body.top .hero__wrap {
      padding-top: 19vw; } }

body.top .hero__bg {
  width: 100%;
  z-index: -1; }

body.top .hero__image {
  vertical-align: bottom;
  width: 100%; }

body.top .hero__box {
  position: absolute;
  top: 39%;
  left: 16.7%; }
  @media all and (max-width: 768px) {
    body.top .hero__box {
      text-align: center;
      top: 26.5%;
      left: 50%;
      transform: translateX(-50%);
      width: 100vw; } }

body.top .hero__title {
  font-family: 'ヒラギノ角ゴシック';
  font-size: 4.1vw;
  font-weight: 600;
  letter-spacing: -2px; }
  @media all and (max-width: 768px) {
    body.top .hero__title {
      font-size: 7.6vw;
      letter-spacing: 0; } }

body.top .hero__title-sub {
  font-size: 1vw;
  text-align: center; }
  @media all and (max-width: 768px) {
    body.top .hero__title-sub {
      font-size: 4vw;
      margin-top: 5px; } }

body.top .section {
  padding: 120px 2% 105px; }
  @media all and (max-width: 768px) {
    body.top .section {
      padding: 80px 5% 80px; } }

body.top .title {
  color: #3f9f28;
  font-family: "Open Sans", sans-serif;
  font-size: 37px;
  font-weight: bold;
  text-align: center; }

body.top .title-sub {
  color: #3f9f28;
  font-size: 19px;
  margin-top: 2px;
  font-weight: bold;
  text-align: center; }

body.top .title-line {
  border-top: 1px solid #3f9f28;
  height: 85px;
  text-align: center; }

body.top .title-box {
  display: inline-block;
  padding: 0 20px;
  transform: translateY(-32%); }

body.top .top-service {
  background: #f2f2f2;
  position: relative; }
  body.top .top-service__wrap {
    max-width: 980px;
    margin: 0 auto;
    position: relative; }
  body.top .top-service__title-box {
    background: #f2f2f2; }
  body.top .top-service__text {
    letter-spacing: 0.2px;
    line-height: 1.6;
    text-align: center; }
    @media all and (max-width: 768px) {
      body.top .top-service__text {
        margin-top: 10px;
        letter-spacing: -1px; } }
  body.top .top-service__contents {
    display: flex;
    flex-wrap: wrap; }
    @media all and (min-width: 769px) {
      body.top .top-service__contents {
        justify-content: center;
        margin-top: 20px; } }
    @media all and (max-width: 768px) {
      body.top .top-service__contents {
        margin-top: 60px; } }
  body.top .top-service__item {
    transition: 0.2s ease-in-out;
    backface-visibility: hidden;
    cursor: pointer;
    background: url(../img/top/service_develop.jpg) 50%/cover no-repeat;
    color: #fff;
    font-size: 30px;
    font-weight: bold;
    margin-top: 35px;
    padding: 100px 0;
    text-align: center;
    flex-basis: 48.6%; }
    body.top .top-service__item:hover {
      opacity: 0.7; }
    body.top .top-service__item::before, body.top .top-service__item::after {
      backface-visibility: hidden; }
    @media all and (max-width: 768px) {
      body.top .top-service__item {
        background: url(../img/top/service_develop_sp.jpg) 50%/cover no-repeat;
        flex-basis: 100%;
        font-size: 5vw;
        margin-top: 0;
        padding: 16% 0; }
        body.top .top-service__item:not(:first-child) {
          margin-top: 25px; } }
    body.top .top-service__item:nth-child(2) {
      background: url(../img/top/service_ses.jpg) 50%/cover no-repeat; }
      @media all and (min-width: 769px) {
        body.top .top-service__item:nth-child(2) {
          background: url(../img/top/service_ses_sp.jpg) 50%/cover no-repeat;
          margin-left: 20px; } }
    body.top .top-service__item:nth-child(3) {
      background: url(../img/top/service_shop.jpg) 50%/cover no-repeat; }
      @media all and (min-width: 769px) {
        body.top .top-service__item:nth-child(3) {
          background: url(../img/top/service_shop_sp.jpg) 50%/cover no-repeat;
          margin-top: 20px; } }
    body.top .top-service__item:nth-child(4) {
      background: url(../img/top/service_web.jpg) 50%/cover no-repeat; }
      @media all and (min-width: 769px) {
        body.top .top-service__item:nth-child(4) {
          background: url(../img/top/service_web_sp.jpg) 50%/cover no-repeat;
          margin-top: 20px;
          margin-left: 20px; } }
    body.top .top-service__item:nth-child(5) {
      background: url(../img/top/service_work.jpg) 50%/cover no-repeat; }
      @media all and (min-width: 769px) {
        body.top .top-service__item:nth-child(5) {
          background: url(../img/top/service_work_sp.jpg) 50%/cover no-repeat;
          margin-top: 20px;
          margin-left: 20px; } }
  body.top .top-service__link {
    margin-top: 40px;
    position: relative;
    text-align: center; }
    @media all and (max-width: 768px) {
      body.top .top-service__link {
        margin-top: 5px; } }
    body.top .top-service__link::before {
      position: absolute;
      display: block;
      content: "";
      background: url(../img/common/arrow_green.png) 50%/cover no-repeat;
      bottom: -6px;
      left: 50%;
      width: 402px;
      height: 16px;
      transform: translateX(-50%); }
      @media all and (max-width: 768px) {
        body.top .top-service__link::before {
          background-size: contain;
          height: 4vmin;
          width: 100%; } }
  body.top .top-service__link-inner {
    transition: 0.2s ease-in-out;
    backface-visibility: hidden;
    cursor: pointer;
    color: #3f9f28;
    font-weight: bold;
    font-size: 30px;
    letter-spacing: -3px; }
    body.top .top-service__link-inner:hover {
      opacity: 0.7; }
    body.top .top-service__link-inner::before, body.top .top-service__link-inner::after {
      backface-visibility: hidden; }

@media all and (max-width: 768px) {
  body.top .top-recruit {
    padding-bottom: 100px; } }

body.top .top-recruit__wrap {
  max-width: 980px;
  margin: 0 auto;
  position: relative; }

body.top .top-recruit__title-box {
  background: #fff; }

body.top .top-recruit__text {
  letter-spacing: 0.2px;
  line-height: 1.6;
  text-align: center; }
  @media all and (max-width: 768px) {
    body.top .top-recruit__text {
      margin-top: 3px;
      letter-spacing: -1px; } }

body.top .top-recruit__contents {
  margin-top: 65px; }
  @media all and (max-width: 768px) {
    body.top .top-recruit__contents {
      margin-top: 35px; } }

body.top .top-recruit__item {
  background: linear-gradient(to right, #fff, #fff 33%, #3f9f28 33%);
  display: flex;
  padding-bottom: 30px; }
  @media all and (max-width: 768px) {
    body.top .top-recruit__item {
      background: none;
      flex-direction: column;
      padding-bottom: 0; } }
  body.top .top-recruit__item:last-child {
    background: linear-gradient(to left, #fff, #fff 33%, #3f9f28 33%);
    margin-top: 80px; }
    @media all and (max-width: 768px) {
      body.top .top-recruit__item:last-child {
        background: none;
        margin-top: 75px; } }

body.top .top-recruit__image {
  margin-top: -30px;
  width: 100%; }
  @media all and (min-width: 769px) {
    body.top .top-recruit__image {
      max-width: 653px; } }
  @media all and (max-width: 768px) {
    body.top .top-recruit__image {
      margin-top: 0;
      padding-right: 6vmin; } }
  @media all and (min-width: 769px) {
    body.top .top-recruit__image--reverse {
      margin-left: 0;
      order: 2; } }

body.top .top-recruit__image-inner {
  vertical-align: bottom; }

body.top .top-recruit__box {
  justify-content: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  max-width: 280px;
  margin: 0 20px;
  width: 50%; }
  @media all and (max-width: 768px) {
    body.top .top-recruit__box {
      background: linear-gradient(to right, #fff, #fff 6%, #3f9f28 6%);
      margin: -15px 0 0;
      max-width: 100%;
      padding: 0 6% 9% 12%;
      width: 100%; } }
  @media all and (min-width: 769px) {
    body.top .top-recruit__box--reverse {
      order: 1; } }

body.top .top-recruit__item-title {
  font-weight: bold;
  margin-top: 15px; }
  @media all and (max-width: 768px) {
    body.top .top-recruit__item-title {
      font-size: 20px;
      margin-top: 30px; } }

body.top .top-recruit__item-text {
  font-weight: bold;
  letter-spacing: -0.3px;
  line-height: 1.5;
  margin-top: 15px; }
  @media all and (max-width: 768px) {
    body.top .top-recruit__item-text {
      margin-top: 5px; } }

body.top .top-recruit__link {
  margin-top: 35px;
  position: relative;
  text-align: center; }
  @media all and (max-width: 768px) {
    body.top .top-recruit__link {
      margin-top: 5px; } }
  body.top .top-recruit__link::before {
    position: absolute;
    display: block;
    content: "";
    background: url(../img/common/arrow_white.png) 50%/contain no-repeat;
    bottom: -6px;
    left: 50%;
    width: 100%;
    height: 16px;
    transform: translateX(-50%); }
    @media all and (max-width: 768px) {
      body.top .top-recruit__link::before {
        background-size: contain;
        bottom: -10px; } }

body.top .top-recruit__link-inner {
  transition: 0.2s ease-in-out;
  backface-visibility: hidden;
  cursor: pointer;
  font-size: 20px; }
  body.top .top-recruit__link-inner:hover {
    opacity: 0.7; }
  body.top .top-recruit__link-inner::before, body.top .top-recruit__link-inner::after {
    backface-visibility: hidden; }

body.top .top-about {
  background: #f2f2f2;
  padding-bottom: 188px;
  position: relative; }
  @media all and (max-width: 768px) {
    body.top .top-about {
      padding-top: 120px; } }
  body.top .top-about::before {
    position: absolute;
    display: block;
    content: "";
    background: url(../img/top/about_leaf.png) 50%/cover no-repeat;
    bottom: -12px;
    left: 51%;
    width: 144px;
    height: 190px;
    transform: translateX(-50%); }
    @media all and (max-width: 768px) {
      body.top .top-about::before {
        left: 56%; } }
  body.top .top-about__wrap {
    max-width: 980px;
    margin: 0 auto;
    position: relative; }
  body.top .top-about__title-box {
    background: #f2f2f2; }
  body.top .top-about__contents {
    margin-top: 5px; }
  body.top .top-about__item {
    border-top: 1px solid #4d4d4d;
    padding: 13px 0; }
    body.top .top-about__item:last-child {
      border-bottom: 1px solid #4d4d4d; }
  body.top .top-about__item-wrap {
    display: flex;
    align-items: baseline; }
  body.top .top-about__heading {
    flex-basis: 14.5%;
    padding-left: 40px; }
    @media all and (max-width: 768px) {
      body.top .top-about__heading {
        flex-basis: 32%;
        padding-left: 0; } }
  body.top .top-about__detail {
    flex-basis: 68%;
    line-height: 1.6; }
  body.top .top-about__address {
    margin-top: 35px; }
    @media all and (max-width: 768px) {
      body.top .top-about__address {
        margin-top: 30px; } }
  body.top .top-about__address-title {
    font-size: 20px;
    text-align: center; }
  body.top .top-about__address-text {
    text-align: center;
    line-height: 1.6;
    margin-top: 5px; }
    @media all and (max-width: 768px) {
      body.top .top-about__address-text {
        letter-spacing: 0; } }
  body.top .top-about__map {
    margin-top: 13px; }
    @media all and (max-width: 768px) {
      body.top .top-about__map {
        margin: 15px calc(((100vw - 100%) / 2) * -1) 0; } }
  body.top .top-about__map-inner {
    width: 100%;
    height: 283px; }

body.top .top-wantedly-story {
  background: #f2f2f2;
  padding-bottom: 40px;
  position: relative; }
  @media all and (max-width: 768px) {
    body.top .top-wantedly-story {
      padding-top: 120px; } }
  body.top .top-wantedly-story__wrap {
    max-width: 980px;
    margin: 0 auto;
    position: relative; }
  body.top .top-wantedly-story__title-box {
    background: #f2f2f2; }
  body.top .top-wantedly-story__contents {
    margin-top: 5px; }
  body.top .top-wantedly-story__item {
    border-top: 1px solid #4d4d4d;
    padding: 13px 0; }
    body.top .top-wantedly-story__item:last-child {
      border-bottom: 1px solid #4d4d4d; }
  body.top .top-wantedly-story__item-wrap {
    display: flex;
    align-items: baseline; }
  body.top .top-wantedly-story__item-wrap > a {
    width: 100%; }
  body.top .top-wantedly-story__item-inner {
    display: flex; }
    @media all and (max-width: 768px) {
      body.top .top-wantedly-story__item-inner {
        display: table-cell; } }
  body.top .top-wantedly-story__item-inner-img {
    width: 200px;
    height: auto; }
    @media all and (max-width: 768px) {
      body.top .top-wantedly-story__item-inner-img {
        width: 100%; } }
  body.top .top-wantedly-story__item-inner-div {
    margin-left: 20px;
    width: auto; }
    @media all and (max-width: 768px) {
      body.top .top-wantedly-story__item-inner-div {
        margin-left: 0; } }

body.top .top-qiita-articles {
  background: #f2f2f2;
  padding-bottom: 188px;
  position: relative; }
  @media all and (max-width: 768px) {
    body.top .top-qiita-articles {
      padding-top: 120px;
      padding-bottom: 290px; } }
  body.top .top-qiita-articles::before {
    position: absolute;
    display: block;
    content: "";
    background: url(../img/top/service_leaf.png) 50%/cover no-repeat;
    bottom: -45px;
    right: 2.1%;
    width: 369px;
    height: 504px; }
    @media all and (max-width: 768px) {
      body.top .top-qiita-articles::before {
        bottom: -33px;
        right: 50%;
        height: 290px;
        width: 212px;
        transform: translateX(140px); } }
  body.top .top-qiita-articles__wrap {
    max-width: 980px;
    margin: 0 auto;
    position: relative; }
  body.top .top-qiita-articles__title-box {
    background: #f2f2f2; }
  body.top .top-qiita-articles__contents {
    margin-top: 5px; }
  body.top .top-qiita-articles__item {
    border-top: 1px solid #4d4d4d;
    padding: 13px 0; }
    body.top .top-qiita-articles__item:last-child {
      border-bottom: 1px solid #4d4d4d; }
  body.top .top-qiita-articles__item:hover .content-title {
    color: #3f9f28; }
  body.top .top-qiita-articles__item-wrap {
    display: flex;
    align-items: baseline; }
  body.top .top-qiita-articles__item-wrap > a {
    width: 100%; }
  body.top .top-qiita-articles__item-inner {
    display: flex; }
    @media all and (max-width: 768px) {
      body.top .top-qiita-articles__item-inner {
        display: table-cell; } }
  body.top .top-qiita-articles__item-inner-div {
    margin-left: 20px;
    width: auto; }
    @media all and (max-width: 768px) {
      body.top .top-qiita-articles__item-inner-div {
        margin-left: 0; } }
  body.top .top-qiita-articles .content-title {
    color: rgba(0, 0, 0, 0.87);
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;
    word-break: break-all; }
